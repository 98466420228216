const menu = document.getElementById("menu");
menu.addEventListener('click', openNav);
const closeBtn = document.getElementById("close-btn");
closeBtn.addEventListener('click', closeNav);

function openNav() {
    document.getElementById("side-nav").style.width = "90%";
}

function closeNav() {
    document.getElementById("side-nav").style.width = "0";
}

const profiles = document.querySelectorAll(".profile-link");
profiles.forEach(value => {
    value.addEventListener('click', openProfile);
});

function openProfile() {
    closeNav();
    document.getElementById("profile-container").style.display = "flex";
}
